
          $base-color: #406C10;
          $base-color-light: #EBFFD5;
          $base-background-color: #F2F2F2;
        
/* Estilo para todos los días */
.react-datepicker__day {
  font-family: "Roboto";
}

/* Estilo para el día seleccionado */
.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  background-color: $base-color; /* Cambia el color de fondo */
  border-radius: 12px; /* Cambia el radio de borde */
}

/* Estilo para el día en hover */
.react-datepicker__day:hover {
  border-radius: 12px; /* Cambia el radio de borde */
}

.react-datepicker__day--keyboard-selected {
  color: white !important;
}
