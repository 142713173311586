table {
  border-radius: 8px;
}

thead {
  tr {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  th {
    border: none !important;
    color: rgba(0, 0, 0, 1);
    vertical-align: middle !important;
  }
}

tbody.condensed {
  font-size: 85%;
}

tbody:not(.condensed) {
  font-size: 95%;
}

tbody {
  td {
    border: none !important;
    vertical-align: middle !important;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

.table > :not(caption) > * > * {
  background-color: transparent !important;
  box-shadow: none !important;
}

.page-link {
  padding: 0.15rem 0.5rem !important;
  font-size: 0.75rem !important;
}

.advance-table {
  table-layout: auto;
}

.advance-table th,
.advance-table td {
  white-space: nowrap; /* Don’t wrap onto a new line */
  overflow: hidden; /* Hide overflow if the text is wider than the cell */
  text-overflow: ellipsis; /* Show "..." if there’s overflow */
}

.table-layout-fixed {
  table-layout: fixed !important;
}

.table-layout-auto {
  table-layout: auto !important;
}
