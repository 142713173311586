
          $base-color: #406C10;
          $base-color-light: #EBFFD5;
          $base-background-color: #F2F2F2;
        
@import "src/assets/scss/1-variables/_app.scss";

.form-template {
  .nav-item {
    background-color: $gray-light;
    border: 1px solid transparent;
    &.is-dragging {
      border: 1px dashed white;
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.06);
    }
    &:has(.active) {
      background-color: white;
      border: 1px solid $gray-light;
    }
    .draggable-icon {
      cursor: grab;
      color: #949494;
    }

    .nav-link {
      color: #757575;
      &.active,
      &.show,
      &:hover,
      &:focus {
        background: transparent;
        border: none;
        outline: none;
      }
    }
  }

  .btn-dashed {
    border: 1px dashed #949494;
    padding: 12px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #949494;
    &:hover {
      color: $primary !important;
      svg {
        color: $primary;
      }
    }
  }

  .btn-text {
    font-size: 14px;
    color: #949494;
    &:hover {
      color: $primary !important;
      svg {
        color: $primary;
      }
    }
  }

  .add-btn-container {
    display: grid;
    margin-left: 24px;
  }
}
