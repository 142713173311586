
          $base-color: #406C10;
          $base-color-light: #EBFFD5;
          $base-background-color: #F2F2F2;
        
table.smart-report {
  th,
  td {
    min-width: 150px;
  }
}
