
          $base-color: #406C10;
          $base-color-light: #EBFFD5;
          $base-background-color: #F2F2F2;
        
@import "src/assets/scss/1-variables/_app.scss";

.upload-photos {
  &__dropzone {
    border: 2px dashed $primary;
    border-radius: 1rem;
    padding: 2rem;
    max-height: 250px;

    background-color: $secondary;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: $primary;
      text-decoration: underline;
      cursor: pointer;
    }

    &.preview-images {
      margin-bottom: 1rem;
    }
  }
}
